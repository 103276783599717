<template>
  <v-row>
    <v-col>
      <v-row class="d-flex flex-row justify-space-between align-center" style="width: 50%;">
        <h3 class="px-3" v-text="`${$t('project.form.component')} ${currentComponent}`" />
        <v-row
          v-if="!isOnlyField && canEdit"
          no-gutters
          style="flex: initial !important;"
          class="align-center"
          @click="$store.commit('dynamicForm/deleteField', field.id)"
        >
          <v-btn
            icon
            x-small
            color="primary"
          >
            <v-icon
              dark
            >
              delete_outline
            </v-icon>
          </v-btn>
          <span
            class="ml-2 primary--text"
          >
            {{ $t(`common.delete`) }}
          </span>
        </v-row>
      </v-row>
      <v-row>
        <v-autocomplete
          v-model="type"
          :items="typeItems"
          item-text="name"
          item-value="type"
          :label="$t('project.form.selectComponent')"
          class="pa-3"
          outlined
          dense
          hide-details="auto"
          style="max-width: 50%;"
          menu-props="closeOnContentClick"
          :disabled="(isPublished && !isLastField) || !canEdit"
        >
          <template
            #item="data"
          >
            <v-list-item
              v-bind="data.attrs"
              @click="type = data.item.type"
              @change="$store.commit('dynamicForm/updateField', { ...field, type: type })"
            >
              <v-list-item-icon>
                <v-icon color="primary">
                  {{ data.item.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <span>{{ data.item.name }}</span>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
        <global-tooltip
          v-if="['info-title', 'text', 'select', 'switch', 'radio', 'checkbox'].includes(type)"
          :text="$t(`project.form.autocompleteTooltip.${type}`)"
        />
      </v-row>
      <v-row v-if="type">
        <v-col cols="6">
          <project-dynamic-form-field-detail
            :type="type"
            :data="field"
            :is-published="isPublished"
            :is-last-field="isLastField"
            :can-edit="canEdit"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="isLastField && canEdit"
        no-gutters
        class="d-flex flex-row align-center mt-3"
        @click="$store.commit('dynamicForm/updateField', { page: field.page })"
      >
        <v-btn
          icon
          x-small
          color="primary"
        >
          <v-icon
            dark
          >
            add_box
          </v-icon>
        </v-btn>
        <span
          class="ml-2 primary--text"
        >
          {{ $t(`project.form.addComponent`) }}
        </span>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ProjectDynamicFormField',
  components: {
    ProjectDynamicFormFieldDetail: () => import('@/modules/dynamicForm/formFieldsComponents/DynamicFormFieldDetail.js'),
    GlobalTooltip: () => import('@/components/GlobalTooltip'),
  },
  props: {
    currentComponent: {
      type: Number,
      default: 1,
    },
    field: {
      type: Object,
      default: null,
    },
    isLastField: {
      type: Boolean,
      default: false,
    },
    isOnlyField: {
      type: Boolean,
      default: false,
    },
    isPublished: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      type: null,
    }
  },
  computed: {
    canEdit: ({ field }) => !field.isStatic,
    typeItems() {
      return [
        { type: 'info-title', name: this.$t('project.form.components.pageTitle'), icon: 'text_fields' },
        { type: 'info-paragraph', name: this.$t('project.form.components.fieldParagraph'), icon: 'short_text' },
        { type: 'text', name: this.$t('project.form.components.fieldText'), icon: 'crop_16_9' },
        { type: 'select', name: this.$t('project.form.components.fieldSelect'), icon: 'arrow_circle_down' },
        { type: 'date', name: this.$t('project.form.components.fieldDate'), icon: 'date_range' },
        { type: 'email', name: this.$t('project.form.components.fieldEmail'), icon: 'mail_outline' },
        { type: 'phone', name: this.$t('project.form.components.fieldPhone'), icon: 'phone' },
        { type: 'switch', name: this.$t('project.form.components.fieldSwitch'), icon: 'toggle_off' },
        { type: 'radio', name: this.$t('project.form.components.fieldRadio'), icon: 'radio_button_on' },
        { type: 'checkbox', name: this.$t('project.form.components.fieldCheckbox'), icon: 'check_box' },
        { type: 'file', name: this.$t('project.form.components.fieldFile'), icon: 'attach_file' },
        { type: 'image', name: this.$t('project.form.components.fieldImage'), icon: 'image' },
      ]
    },
  },
  created() {
    this.type = this?.field?.type
  },
}
</script>

<style scoped>
.primary--text {
  cursor: pointer;
}
</style>
